import "./index.scss";
import "../../../index.scss";

import {
  Button,
  Col,
  Divider,
  Flex,
  Image,
  Row,
  Space,
  Tabs,
  TabsProps,
  Typography,
} from "antd";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { ArrowLeftOutlined, PhoneOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

// import { turfData } from "../../../config/turf.config";
import { TileProps } from "../../Interfaces";
// import CarouselModel from "../../../components/ImagesCarousel";
// import TurfInfoList from "../TileContainer/TurfInfoList";
// import { getSubdomain } from "../../../utils/getSubDomain";
import constants from "../../../utils/constants";
import MapComponent from "../../../components/Map/Index";
import OverviewComponent from "../../../components/DetailPageComponent/Overview";
import HelmetComponent from "../../../components/Helmet/HelmetComponent";
import TabPane from "antd/es/tabs/TabPane";
import { useMediaQuery } from "react-responsive";
import { showBookNow } from "../../../utils/showFeatures";
import ShareButton from "../../../components/ShareButton/index";

// import TabComponent from "../../../components/tab";

interface AreaPrice {
  area: string;
  pitchDimensions: {
    length: number;
    width: number;
    height: number;
  };
  price: {
    weekday: {
      regularHours: number;
      happyHours: {
        range: string;
        price: number;
      };
    };
    weekend: {
      regularHours: number;
      happyHours: {
        range: string;
        price: number;
      };
    };
  };
}

const turfObj = {
  name: "",
  imageUrl: [],
  fields: [],
  data: [],
  bannerImageUrl: "",
  userName: ""
}

function SingleTileContainer({
  name,
  turfData,
  city,
}: {
  name: string | null;
  turfData:any[];
  city: string;
}) {
  const { Title, Text } = Typography;
  // const carouselRef = useRef<HTMLDivElement>(null);
  // const [activeMenu, setActiveMenu] = useState<string | null>("overview");

  let arr: any[] = [];
  arr.length = 10;

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const packageCity = queryParams.get('city');
  const imageUrl = JSON.parse(queryParams.get('imageUrl') || '[]');
  const fields = JSON.parse(queryParams.get('fields') || '[]');
  const data = JSON.parse(queryParams.get('data') || '{}');
  const price = queryParams.get('price'); // Retrieve price
  const area = queryParams.get('area');
  const [turf, setTurf] = useState<TileProps>({ ...turfObj, imageUrl, fields, data });
  const areaPriceObj = turf?.data?.areaPrice || [];
  // const price = areaPriceObj[0]?.price?.weekday?.regularHours;

  const isRainProtected = turf?.data?.isRainProtected;
  const hasCafe = turf?.data?.hasCafe;

  useEffect(() => {
    const currentTurf = turfData.find(turf => turf.userName === name) || turfObj;
    setTurf(currentTurf);
  }, [name]);

  const goBack = () => {
    window.location.href = constants.HOST_URL;
    sessionStorage.setItem(window.location.pathname, window.scrollY.toString());
    // navigate(-1);
  };

  const handleBookNow = () => {
    navigate(`/booking?area=${area}&price=${price}`);
  };

  // const handleScrollLeft = () => {
  //   if (carouselRef.current) {
  //     carouselRef.current.scrollLeft -= 200;
  //   }
  // };

  // const handleScrollRight = () => {
  //   if (carouselRef.current) {
  //     carouselRef.current.scrollLeft += 200;
  //   }
  // };

  // const handleScroll = (id: string) => {
  //   const targetHeading = document.getElementById(id);
  //   setActiveMenu(id);
  //   if (targetHeading) {
  //     targetHeading.scrollIntoView({ behavior: "smooth" });
  //   }
  // };
  const url = window.location.href;
  const imgMeta = `${turf.name} | ${turf.data.city} | TurfBooking`;

  const [activeTab, setActiveTab] = useState("weekdays");
  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };
  const [selectedArea, setSelectedArea] = useState<string | undefined>(undefined);
  const [selectedItem, setSelectedItem] = useState(areaPriceObj[0]);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    const matchingAreaIndex = areaPriceObj.findIndex((item: AreaPrice) => item.area === area);
    if (matchingAreaIndex !== -1) {
      setSelectedArea(areaPriceObj[matchingAreaIndex].area);
      setSelectedItem(areaPriceObj[matchingAreaIndex]);
      setSelected(matchingAreaIndex);
    } else {
      setSelectedItem(areaPriceObj[0]);
    }
  }, [area, areaPriceObj]);

  const handleArea = (item: AreaPrice) => {
    setSelectedItem(item);
  };


  const items: TabsProps["items"] = [
    {
      key: "overview",
      label: <Title level={5}>Overview</Title>,
      children: (
        <Flex vertical gap={24}>
          <Flex vertical gap={2}>
            <Title level={2}>Overview</Title>
            <OverviewComponent areaPrice={areaPriceObj} />
          </Flex>
          <Row gutter={[16, 16]} justify={"space-between"}>
            <Col md={8} sm={24} xs={24}>
              <Flex vertical gap={8} className="flex-cards">
                <Title level={3}>Pricing</Title>
                {areaPriceObj.some((item: AreaPrice) => item.pitchDimensions) && (
              <Text strong={isMobile}>Pitch available (L*B*H) - </Text>
            )}
                <Flex vertical gap={4} style={{ flexDirection: "column" }}>
                  {areaPriceObj.map((item: AreaPrice, i: number) => {
                    return (
                      item.pitchDimensions && <div className="area-container">
                        <div className="turf-area-options">
                          <Button
                            key={i}
                            style={{
                              padding: "0.1rem 0.5rem",
                              height: "fit-content",
                              backgroundColor:
                                i === selected ? "purple" : "white",
                              color: i === selected ? "white" : "black",
                              border: "1px solid black",
                              fontSize: isMobile ? "10px" : undefined,
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              // setSelectedArea(item.area);
                              setSelected(i);
                              handleArea(item);
                            }}
                            type={i === 0 ? "primary" : "default"}
                            shape="round"
                          >
                            {item.area} sq. ft ( {item.pitchDimensions.length}*{item.pitchDimensions.width}*{item.pitchDimensions.height} ft )
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </Flex>
                {/* <TabComponent selectedItem={selectedItem} handleTabChange={handleTabChange} /> */}
                <Tabs
                  defaultActiveKey="weekdays"
                  size="large"
                  activeKey={activeTab}
                  onChange={handleTabChange}
                >
                  <TabPane tab="Weekdays" key="weekdays">
                    <Title level={5}>
                      Regular Price -{" "}
                      {selectedItem?.price.weekday?.regularHours}/-
                    </Title>
                    <Title level={5}>
                      {selectedItem?.price.weekday?.happyHours?.range} {selectedItem?.price?.weekday?.happyHours?.price && `- ${selectedItem?.price?.weekday?.happyHours?.price} /-`}
                    </Title>
                  </TabPane>
                  <TabPane tab="Weekend" key="weekend">
                    <Title level={5}>
                      Regular Price -{" "}
                      {selectedItem?.price.weekend?.regularHours}/-
                    </Title>
                    <Title level={5}>
                      {selectedItem?.price?.weekend?.happyHours?.range} {selectedItem?.price?.weekend?.happyHours?.price && `- ${selectedItem?.price?.weekend?.happyHours?.price} /-`}
                    </Title>
                  </TabPane>
                </Tabs>
              </Flex>
            </Col>
            <Col md={8} sm={12} xs={12}>
              <Flex vertical gap={8} className="flex-cards">
                <Title level={3}>Cafe</Title>
                <Text>
                  {hasCafe ? (
                    <>
                      Certainly! <strong>{turf.name}</strong> features a
                      cafeteria that offers delectable meals, enhancing the
                      overall experience of your game.
                    </>
                  ) : (
                    <span>
                      <strong>{turf.name}</strong> doesn't have a cafe yet.
                    </span>
                  )}
                </Text>
              </Flex>
            </Col>
            <Col md={8} sm={12} xs={12}>
              <Flex vertical gap={8} className="flex-cards">
                <Title level={3}>Contact</Title>
                <div>
                  {(turf?.data?.partnership
                    ? turf?.data?.displayContact
                    : turf?.data?.actualContact
                  )
                    ?.split("/")
                    .map((item: string, index: number) => {
                      return (
                        <Flex gap={4} key={index} align="center">
                          <PhoneOutlined />
                          <Link to={`tel:${item}`}>{item}</Link>
                        </Flex>
                      );
                    })}
                </div>
              </Flex>
            </Col>
          </Row>

          <Divider />
          <Flex vertical align="center" gap={12}>
            <MapComponent
              latitude={turf?.data?.location?.latitude}
              longitude={turf?.data?.location?.longitude}
            />
            <Text type="secondary">{turf?.data?.address}</Text>
          </Flex>
        </Flex>
      ),
    },
    {
      key: "facilities",
      label: <Title level={5}>Facilities</Title>,
      children: (
        <Flex vertical gap={12}>
          <Title level={2}>Facilities</Title>
          <Row gutter={[2, 2]}>
            <Col xs={24}>
              <Title level={4}>
                Some of the many facilities available are:
              </Title>
            </Col>
            <Col span={24}>
              <ul
                style={{
                  listStylePosition: "inside",
                }}
              >
                {turf.data.facilities ? (
                  <>
                    {turf.data.facilities.map(
                      (facility: string, index: number) => (
                        <li key={index}>{facility}</li>
                      )
                    )}
                  </>
                ) : (
                  <>
                    <li>Great ambience </li>
                    <li> {areaPriceObj.length} Turf(s) </li>
                    {isRainProtected && <li> Rain covered </li>}
                    {hasCafe && <li> Cafeteria present </li>}
                    <li> Provides playing equipments </li>
                  </>
                )}
              </ul>
            </Col>
          </Row>
        </Flex>
      ),
    },
    {
      key: "gallery",
      label: <Title level={5}>Gallery</Title>,
      children: (
        <Flex vertical gap={12}>
          <Title level={2}>Gallery</Title>
          <Row justify={"space-between"} gutter={[16, 16]}>
            {turf?.imageUrl.map((item) => {
              return (
                <Col md={8} sm={12} xs={24} key={item}>
                  <Image
                    src={item}
                    alt={imgMeta}
                    title={imgMeta}
                    // preview={false}
                    key={item}
                    height={250}
                    width={"100%"}
                    style={{
                      borderRadius: "1rem",
                      objectFit: "cover",
                    }}
                  />
                </Col>
              );
            })}
          </Row>
        </Flex>
      ),
    },
    // {
    //   key: "offers",
    //   label: <Title level={5}>Offers</Title>,
    //   children: (
    //     <Flex vertical gap={12}>
    //       <Title level={2}>Offers</Title>
    //       {turf.data.webOffers && turf.data.webOffers.map((offer: string, index: number) => (
    //         <Image
    //           key={index}
    //           src={offer}
    //           alt={`Offer ${index + 1}`}
    //           title={`Offer ${index + 1}`}
    //           height={250}
    //           width={"100%"}
    //           style={{
    //             borderRadius: "1rem",
    //             // objectFit: "cover",
    //           }}
    //         />
    //       ))}
    //     </Flex>
    //   ),
    // },
  ];

  return (
    <>
      {turf.fields.length > 0 && (
        <>
          <HelmetComponent
            title={`${turf?.name} | TurfBooking`}
            description={`${turf?.name} | address: ${turf?.data?.address} | TurfBooking `}
            link={url}
          />
          <div className="single-tile-container">
            <Row justify={"center"}>
              <Button
                className="back-button"
                onClick={goBack}
                icon={<ArrowLeftOutlined />}
                shape="circle"
                type="primary"
              />
              <Row className="header-row" align={"stretch"} justify={"center"}>
                <Col
                  md={8}
                  sm={24}
                  style={{
                    width: "100%",
                  }}
                >
                  <Image
                    src={
                      turf?.bannerImageUrl
                        ? turf.bannerImageUrl
                        : turf?.imageUrl[0]
                    }
                    alt={imgMeta}
                    title={imgMeta}
                    width={"100%"}
                    className="banner-image"
                    preview={false}
                  />
                </Col>
                <Col
                  md={16}
                  sm={24}
                  style={{
                    backgroundColor: "rgba(57, 16, 133, 0.05)",
                  }}
                >
                  <div className="banner-content-parent">
                    <Row className="banner-content" gutter={[0, 48]}>
                      <Col xs={24}>
                        <Space direction="vertical">
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <Title className="turf-title">{turf?.name}</Title>
                            <ShareButton
                              className='share-btn'
                              latitude={turf?.data?.location?.latitude}
                              longitude={turf?.data?.location?.longitude}
                            />
                          </div>
                          <Title level={4}>{turf?.data?.address} </Title>
                          <Title level={4}>{`Estimated Price: ₹${price}`} </Title>
                        </Space>
                      </Col>
                      <Row className="detail-menu-row">
                        <Col xs={0} md={24} className="banner-content-col2">
                          <Row>
                            <Col xs={12}>
                              {showBookNow(turf.data.city) && (
                                <Button
                                  size="large"
                                  type="primary"
                                  shape="round"
                                  onClick={handleBookNow}
                                  style={{
                                    width: "fit-content",
                                    fontWeight: 500,
                                  }}
                                >
                                  Continue
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Col
                sm={24}
                md={18}
                lg={16}
                style={{
                  padding: "20px",
                }}
              >
                <Tabs defaultActiveKey="overview" items={items} />
              </Col>
            </Row>

            <Col xs={24} md={0} className="book-now-btn-container">
              <Row
                align={"middle"}
                style={{
                  padding: "1rem",
                }}
              >
                <Col xs={12}>
                  <Flex vertical gap={8}>
                    <Title level={3}>Estimated Price</Title>
                    <Text>₹{price}</Text> {/* Display the price here */}
                  </Flex>
                </Col>
                {showBookNow(turf.data.city) && (
                  <Col xs={12}>
                    <Button
                      type="primary"
                      shape="round"
                      size="large"
                      block={true}
                      onClick={handleBookNow}
                    >
                      Continue
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </div>
        </>
      )}
    </>
  );
}

export default SingleTileContainer;
